.block {
  display: block;
  text-align: center;
  font-size: 50px;
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: white;
}
